<template>
  <label class="relative flex flex-row">
    <input
      type="radio"
      :checked="checked"
      class="absolute opacity-0 w-7 h-7"
      @change="changed"
      v-bind="$attrs"
      v-validate="validation"
    >
    <img
      v-if="checked"
      src="@/assets/images/radio_button_checked.svg"
      :alt="$t('general.label_checked')"
      class="h-7 w-7"
    >
    <img
      v-else
      src="@/assets/images/radio_button_unchecked.svg"
      :alt="$t('general.label_unchecked')"
      class="h-7 w-7"
    >
  </label>
</template>

<script>
  export default {
    name: 'VRadio',
    inheritAttrs: false,
    props: {
      validation: { type: String, default: '' },
      checked: { type: Boolean, default: false },
    },
    inject: {
      $validator: '$validator',
    },
    methods: {
      changed() {
        this.$emit('input', !this.checked);
      },
    },
  };
</script>
