var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.teams, function(team) {
      return _c(
        "li",
        { key: team.id, staticClass: "focus:bg-gray-100 hover:bg-gray-100" },
        [
          _c(
            "label",
            { staticClass: "detail-field flex cursor-pointer" },
            [
              _c("p", {
                staticClass: "truncate",
                domProps: { textContent: _vm._s(team.name) }
              }),
              _c("v-radio", {
                attrs: {
                  name: "team",
                  checked: _vm.selectedTeam && team.id === _vm.selectedTeam.id
                },
                on: {
                  input: function($event) {
                    return _vm.teamSelected(team)
                  }
                }
              })
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }