var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(Object.values(_vm.TeamUserRoles), function(role) {
      return _c(
        "li",
        { key: role, staticClass: "focus:bg-gray-100 hover:bg-gray-100" },
        [
          _c(
            "label",
            { staticClass: "detail-field flex cursor-pointer" },
            [
              _c("p", {
                staticClass: "truncate",
                domProps: {
                  textContent: _vm._s(_vm.$t("team_user_roles." + role))
                }
              }),
              _c("v-radio", {
                attrs: { name: "role", checked: role === _vm.selectedRole },
                on: {
                  input: function($event) {
                    return _vm.roleSelected(role)
                  }
                }
              })
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }