<template>
  <ul>
    <li
      v-for="team in teams"
      :key="team.id"
      class="focus:bg-gray-100 hover:bg-gray-100">
      <label class="detail-field flex cursor-pointer">
        <p
          v-text="team.name"
          class="truncate"></p>
        <v-radio
          name="team"
          :checked="selectedTeam && team.id === selectedTeam.id"
          @input="teamSelected(team)"
        />
      </label>
    </li>
  </ul>
</template>

<script>
  import VRadio from '@/components/common/VRadio.vue';
  import Team from '@/models/Team';

  export default {
    name: 'SelectableTeamsList',
    components: {
      VRadio,
    },
    props: {
      teams: { type: Array, default: () => [] },
      selectedTeam: { type: Team, default: null },
    },
    methods: {
      teamSelected(team) {
        this.$emit('selected', team);
      },
    },
  };
</script>
