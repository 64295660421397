<template>
  <div class="flex flex-col">
    <nav class="flex items-center mt-3 px-3 pb-2 border-b border-blue-500">
      <div class="w-1/3">
        <v-back
          v-if="!showTeamSelection && !showRoleSelection"
          :fallback="$router.resolve({ name: 'teams-overview'})"
        />
        <button
          v-else
          class="flex justify-start btn btn-round"
          @click="back"
        >
          <img
            src="@/assets/images/back.svg"
            :alt="$t('general.button_back')"
            class="w-7 h-7">
        </button>
      </div>
      <p
        v-text="$t('the_invite.label_invite')"
        class="font-semibold w-1/3 flex justify-center pointer-events-none"
      ></p>
      <div class="w-1/3 flex justify-end">
        <button
          class="btn btn-blue rounded-full py-1 px-2"
          v-text="$t('the_invite.label_invite')"
          :disabled="inviting"
          :class="{'btn-loading': inviting}"
          @click="invite"
        ></button>
      </div>
    </nav>
    <ul class="list-disc list-inside">
      <li
        v-if="invitingError"
        v-text="invitingError"
        class="form-error"></li>
      <li
        v-if="errors.has('role')"
        v-text="errors.first('role')"
        class="form-error"></li>
      <li
        v-if="errors.has('phone')"
        v-text="errors.first('phone')"
        class="form-error"></li>
      <li
        v-if="errors.has('email')"
        v-text="errors.first('email')"
        class="form-error"></li>
    </ul>
    <form
      v-if="!loading && !error && !showTeamSelection && !showRoleSelection"
      class="flex-grow flex flex-col"
      @submit.prevent="invite"
    >
      <div class="flex-grow">
        <div class="w-full flex">
          <button
            class="form-fieldset flex-1 focus:outline-none"
            @click.prevent="openTeamSelection"
          >
            <p
              v-text="$t('the_invite.label_team')"
              class="form-input-label"></p>
            <input
              type="text"
              :value="selectedTeam ? selectedTeam.name : null"
              v-validate="'required'"
              :placeholder="$t('general.placeholder_required')"
              class="form-input cursor-pointer"
              disabled
              name="team"
            >
            <img
              src="@/assets/images/ic_chevron_right_blue.svg"
              class="w-3 h-3">
          </button>
        </div>
        <div class="w-full flex">
          <button
            class="form-fieldset flex-1 focus:outline-none"
            @click.prevent="openRoleSelection"
          >
            <p
              v-text="$t('the_invite.label_role')"
              class="form-input-label"></p>
            <input
              type="text"
              :value="user.role ? $t(`team_user_roles.${user.role}`) : null"
              v-validate="'required'"
              :placeholder="$t('general.placeholder_required')"
              class="form-input cursor-pointer"
              disabled
              name="role"
            >
            <img
              src="@/assets/images/ic_chevron_right_blue.svg"
              class="w-3 h-3">
          </button>
        </div>
        <div class="form-fieldset">
          <p
            v-text="$t('the_invite.label_firstname')"
            class="form-input-label"></p>
          <input
            type="text"
            v-model="user.firstname"
            name="firstname"
            data-vv-validate-on="blur"
            class="form-input"
            :placeholder="$t('general.placeholder_optional')"
          >
        </div>
        <div class="form-fieldset">
          <p
            v-text="$t('the_invite.label_lastname')"
            class="form-input-label"></p>
          <input
            type="text"
            v-model="user.lastname"
            name="lastname"
            data-vv-validate-on="blur"
            class="form-input"
            :placeholder="$t('general.placeholder_optional')"
          >
        </div>
        <div class="form-fieldset">
          <p
            v-text="$t('the_invite.label_phone')"
            class="form-input-label"></p>
          <input
            type="text"
            v-model="user.phone"
            name="phone"
            v-validate="'required'"
            data-vv-validate-on="blur"
            class="form-input"
            :placeholder="$t('general.placeholder_required')"
          >
        </div>
        <div class="form-fieldset">
          <p
            v-text="$t('the_invite.label_email')"
            class="form-input-label"></p>
          <input
            type="text"
            v-model="user.email"
            name="email"
            v-validate="{'email': true, 'required': user.role === TeamUserRoles.SUPERVISOR}"
            data-vv-validate-on="blur"
            class="form-input"
            :placeholder="user.role === TeamUserRoles.SUPERVISOR ?
              $t('general.placeholder_required') :
              $t('general.placeholder_optional')"
          >
        </div>
        <div class="mx-4 text-sm text-gray-700 mt-5">
          <p v-text="$t('the_invite.label_info_phone')"></p>
        </div>
      </div>
    </form>
    <selectable-teams-list
      v-if="showTeamSelection"
      class="flex-grow"
      :teams="teams.filter((team) => team.canAddUser)"
      :selectedTeam="selectedTeam"
      @selected="selectedTeamUpdated"
    />
    <selectable-team-user-roles-list
      v-if="showRoleSelection"
      class="flex-grow"
      :selectedRole="user.role"
      @selected="roleUpdated"
    />
    <v-loading v-if="loading"/>
    <v-error
      v-if="error"
      v-model="error"/>
  </div>
</template>

<script>
  import validator from '@/mixins/validator';
  import SelectableTeamUserRolesList from '@/components/lists/SelectableTeamUserRolesList.vue';
  import SelectableTeamsList from '@/components/lists/SelectableTeamsList.vue';
  import VLoading from '@/components/common/VLoading.vue';
  import VError from '@/components/common/VError.vue';
  import VBack from '@/components/common/VBack.vue';
  import User from '@/models/User';
  import TeamUserRoles from '@/constants/TeamUserRoles';

  export default {
    name: 'TheInvite',
    components: {
      SelectableTeamUserRolesList,
      SelectableTeamsList,
      VLoading,
      VError,
      VBack,
    },
    mixins: [validator],
    props: {
      teamId: { type: String, default: null },
    },
    data() {
      return {
        user: new User(),
        selectedTeam: null,
        error: null,
        loading: false,
        TeamUserRoles,
        showTeamSelection: false,
        showRoleSelection: false,
        inviting: false,
        invitingError: null,
      };
    },
    computed: {
      teams() {
        return this.$store.getters['team/list'];
      },
      event() {
        return this.$store.getters['auth/event'];
      },
    },
    created() {
      this.loadTeams().then(this.loadSelectedTeam);
    },
    beforeDestroy() {
      this.$store.commit('team/clearList');
      this.$store.commit('team/clearItem');
    },
    methods: {
      loadTeams() {
        this.loading = true;

        return this.$store
          .dispatch('team/loadByEventId', this.event.id)
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      loadSelectedTeam() {
        if (!this.$route.query.teamId) {
          this.loading = false;

          this.selectedTeamUpdated(null);

          return Promise.resolve();
        }

        this.loading = true;

        return this.$store
          .dispatch('team/loadById', this.$route.query.teamId)
          .then(() => {
            this.loading = false;

            this.selectedTeamUpdated(this.$store.getters['team/item']);
          })
          .catch(() => {
            this.loading = false;

            this.selectedTeamUpdated(null);
          });
      },
      openTeamSelection() {
        this.showTeamSelection = true;
      },
      openRoleSelection() {
        this.showRoleSelection = true;
      },
      selectedTeamUpdated(team) {
        if (team && !team.canAddUser) {
          team = null;
        }

        this.selectedTeam = team;

        this.$router.replace({
          query: {
            teamId: this.selectedTeam ? this.selectedTeam.id : null,
          },
        });

        this.back();
      },
      roleUpdated(role) {
        this.user.role = role;

        this.back();
      },
      invite() {
        this.invitingError = null;

        if (this.inviting) {
          return;
        }

        this.$validator.validate().then(() => {
          if (this.$validator.errors.items.length > 0) {
            return;
          }

          this.inviting = true;

          this.$store
            .dispatch('user/invite', { user: this.user, team: this.selectedTeam })
            .then(() => {
              this.inviting = false;

              this.$router.push({
                name: 'team-detail',
                params: { id: this.selectedTeam.id },
              });
            })
            .catch(this.handleError);
        });
      },
      handleError(error) {
        this.inviting = false;

        if (!error.response || !error.response.data) {
          this.invitingError = this.$t('general.label_error');

          return;
        }

        this.invitingError = error.response.data.message;

        if (Object.keys(error.response.data.errors).length > 0) {
          [[this.invitingError]] = Object.values(error.response.data.errors);
        }
      },
      back() {
        this.showTeamSelection = false;
        this.showRoleSelection = false;
      },
    },
  };
</script>
