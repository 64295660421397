var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _c(
        "nav",
        {
          staticClass:
            "flex items-center mt-3 px-3 pb-2 border-b border-blue-500"
        },
        [
          _c(
            "div",
            { staticClass: "w-1/3" },
            [
              !_vm.showTeamSelection && !_vm.showRoleSelection
                ? _c("v-back", {
                    attrs: {
                      fallback: _vm.$router.resolve({ name: "teams-overview" })
                    }
                  })
                : _c(
                    "button",
                    {
                      staticClass: "flex justify-start btn btn-round",
                      on: { click: _vm.back }
                    },
                    [
                      _c("img", {
                        staticClass: "w-7 h-7",
                        attrs: {
                          src: require("@/assets/images/back.svg"),
                          alt: _vm.$t("general.button_back")
                        }
                      })
                    ]
                  )
            ],
            1
          ),
          _c("p", {
            staticClass:
              "font-semibold w-1/3 flex justify-center pointer-events-none",
            domProps: { textContent: _vm._s(_vm.$t("the_invite.label_invite")) }
          }),
          _c("div", { staticClass: "w-1/3 flex justify-end" }, [
            _c("button", {
              staticClass: "btn btn-blue rounded-full py-1 px-2",
              class: { "btn-loading": _vm.inviting },
              attrs: { disabled: _vm.inviting },
              domProps: {
                textContent: _vm._s(_vm.$t("the_invite.label_invite"))
              },
              on: { click: _vm.invite }
            })
          ])
        ]
      ),
      _c("ul", { staticClass: "list-disc list-inside" }, [
        _vm.invitingError
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.invitingError) }
            })
          : _vm._e(),
        _vm.errors.has("role")
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.errors.first("role")) }
            })
          : _vm._e(),
        _vm.errors.has("phone")
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.errors.first("phone")) }
            })
          : _vm._e(),
        _vm.errors.has("email")
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.errors.first("email")) }
            })
          : _vm._e()
      ]),
      !_vm.loading &&
      !_vm.error &&
      !_vm.showTeamSelection &&
      !_vm.showRoleSelection
        ? _c(
            "form",
            {
              staticClass: "flex-grow flex flex-col",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.invite($event)
                }
              }
            },
            [
              _c("div", { staticClass: "flex-grow" }, [
                _c("div", { staticClass: "w-full flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "form-fieldset flex-1 focus:outline-none",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openTeamSelection($event)
                        }
                      }
                    },
                    [
                      _c("p", {
                        staticClass: "form-input-label",
                        domProps: {
                          textContent: _vm._s(_vm.$t("the_invite.label_team"))
                        }
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-input cursor-pointer",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("general.placeholder_required"),
                          disabled: "",
                          name: "team"
                        },
                        domProps: {
                          value: _vm.selectedTeam ? _vm.selectedTeam.name : null
                        }
                      }),
                      _c("img", {
                        staticClass: "w-3 h-3",
                        attrs: {
                          src: require("@/assets/images/ic_chevron_right_blue.svg")
                        }
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "w-full flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "form-fieldset flex-1 focus:outline-none",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openRoleSelection($event)
                        }
                      }
                    },
                    [
                      _c("p", {
                        staticClass: "form-input-label",
                        domProps: {
                          textContent: _vm._s(_vm.$t("the_invite.label_role"))
                        }
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "form-input cursor-pointer",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("general.placeholder_required"),
                          disabled: "",
                          name: "role"
                        },
                        domProps: {
                          value: _vm.user.role
                            ? _vm.$t("team_user_roles." + _vm.user.role)
                            : null
                        }
                      }),
                      _c("img", {
                        staticClass: "w-3 h-3",
                        attrs: {
                          src: require("@/assets/images/ic_chevron_right_blue.svg")
                        }
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "form-fieldset" }, [
                  _c("p", {
                    staticClass: "form-input-label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("the_invite.label_firstname"))
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.firstname,
                        expression: "user.firstname"
                      }
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "text",
                      name: "firstname",
                      "data-vv-validate-on": "blur",
                      placeholder: _vm.$t("general.placeholder_optional")
                    },
                    domProps: { value: _vm.user.firstname },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "firstname", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-fieldset" }, [
                  _c("p", {
                    staticClass: "form-input-label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("the_invite.label_lastname"))
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.lastname,
                        expression: "user.lastname"
                      }
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "text",
                      name: "lastname",
                      "data-vv-validate-on": "blur",
                      placeholder: _vm.$t("general.placeholder_optional")
                    },
                    domProps: { value: _vm.user.lastname },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "lastname", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-fieldset" }, [
                  _c("p", {
                    staticClass: "form-input-label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("the_invite.label_phone"))
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.phone,
                        expression: "user.phone"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "text",
                      name: "phone",
                      "data-vv-validate-on": "blur",
                      placeholder: _vm.$t("general.placeholder_required")
                    },
                    domProps: { value: _vm.user.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "phone", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-fieldset" }, [
                  _c("p", {
                    staticClass: "form-input-label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("the_invite.label_email"))
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          email: true,
                          required:
                            _vm.user.role === _vm.TeamUserRoles.SUPERVISOR
                        },
                        expression:
                          "{'email': true, 'required': user.role === TeamUserRoles.SUPERVISOR}"
                      }
                    ],
                    staticClass: "form-input",
                    attrs: {
                      type: "text",
                      name: "email",
                      "data-vv-validate-on": "blur",
                      placeholder:
                        _vm.user.role === _vm.TeamUserRoles.SUPERVISOR
                          ? _vm.$t("general.placeholder_required")
                          : _vm.$t("general.placeholder_optional")
                    },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "email", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "mx-4 text-sm text-gray-700 mt-5" }, [
                  _c("p", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("the_invite.label_info_phone"))
                    }
                  })
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm.showTeamSelection
        ? _c("selectable-teams-list", {
            staticClass: "flex-grow",
            attrs: {
              teams: _vm.teams.filter(function(team) {
                return team.canAddUser
              }),
              selectedTeam: _vm.selectedTeam
            },
            on: { selected: _vm.selectedTeamUpdated }
          })
        : _vm._e(),
      _vm.showRoleSelection
        ? _c("selectable-team-user-roles-list", {
            staticClass: "flex-grow",
            attrs: { selectedRole: _vm.user.role },
            on: { selected: _vm.roleUpdated }
          })
        : _vm._e(),
      _vm.loading ? _c("v-loading") : _vm._e(),
      _vm.error
        ? _c("v-error", {
            model: {
              value: _vm.error,
              callback: function($$v) {
                _vm.error = $$v
              },
              expression: "error"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }