<template>
  <ul>
    <li
      v-for="role in Object.values(TeamUserRoles)"
      :key="role"
      class="focus:bg-gray-100 hover:bg-gray-100">
      <label class="detail-field flex cursor-pointer">
        <p
          v-text="$t(`team_user_roles.${role}`)"
          class="truncate"></p>
        <v-radio
          name="role"
          :checked="role === selectedRole"
          @input="roleSelected(role)"
        />
      </label>
    </li>
  </ul>
</template>

<script>
  import VRadio from '@/components/common/VRadio.vue';
  import TeamUserRoles from '@/constants/TeamUserRoles';

  export default {
    name: 'SelectableTeamUserRolesList',
    components: {
      VRadio,
    },
    props: {
      selectedRole: { type: String, default: null },
    },
    data() {
      return {
        TeamUserRoles,
      };
    },
    methods: {
      roleSelected(role) {
        this.$emit('selected', role);
      },
    },
  };
</script>
